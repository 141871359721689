<template>
    <div id="index" style="user-select:none;">
<!--      背景-->
      <img class="video" src="@/assets/images/back.gif">

        <baseNav></baseNav>

      <div class="home">
<!--        标题-->
        <div class="title animated bounceInUp">
<!--          <div v-if="indexListData.title&&indexListData.title.length>0" v-html="indexListData.title[0].title"></div>-->
          <div >
            <span  v-if="indexListData.title&&indexListData.title.length>0&&indexListData.title[0].title">{{indexListData.title[0].title}}</span>
            <span v-if="indexListData.title&&indexListData.title.length>0&&indexListData.title[0].content">{{indexListData.title[0].content}}</span>
          </div>
        </div>
<!--        副标题-->
        <div class="cont animated bounceInUp">
          <div >
            <span v-for="(item,index) in indexListData.subtitle" :key="index">{{item.title}}<span v-show="index <= indexListData.subtitle.length - 2">·</span></span>
          </div>
        </div>
        <v-touch @press="press" @pressup="pressup" @tap="tap">
          <div class="cli_btn animated bounceInUp" :class="{press_btn:isPress}">
            <div >Enter <span></span> </div>
            <!-- <router-link to=""><div >Enter <span></span> </div></router-link> -->
            
          </div>
        </v-touch>
<!--版权信息-->
        <Copyright class="animated bounceInUp"></Copyright>
      </div>
    </div>
</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import {GetIndexDoc} from "@/api/api";
    export default {
        name: "Index",
      data(){
          return{
            isPress:false,
            //首页数据
            indexListData: []
          }
      },
      mounted() {
          this.toGetIndexDoc(1)
      },
      methods:{
          //获取首页数据
        toGetIndexDoc(data){
          let _this=this
          GetIndexDoc(data)
              .then(r=>{
                let res=r.data
                if(res.status!=1){
                  _this.indexListData=[]
                  return
                }
                console.log(res.data)
                let apiIndex=res.data
                _this.indexListData=apiIndex
              })
        },
        //长按按钮
        press(){
          this.isPress=true
        },
        //抬起按钮瞬间
        pressup(){
          this.isPress=false
          this.$router.push('/index/about/index')
        },
        //点击按钮
        tap(){
          let _this=this
          _this.isPress=true
          setTimeout(function (){
            _this.$router.push('/index/about/index')
          },100)
        }
        },


      components:{
        baseNav,
        Copyright
      }
    }
</script>

<style lang="less" scoped>
  .video {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100vw;
    height: 100vh;
    z-index: -9999;
  }
  .home {
    height: 100vh;
    width: 100%;
    position: fixed;
    top:13.888vw;
    left: 50%;
    transform: translateX(-50%);
    z-index: -9980;
    .title {
      width: 100%;
      //margin: 11.1842vh auto 0;
      margin: 13.9473vh auto 0;
      font-size: 11.111vw;
      line-height: 16.666vw;
      color: #fff;
      text-align: center;
      font-weight:200;
      span{
        display: block;
        height: 15.27vw;
        font-size: 12.5vw;
        line-height: 15.27vw;
      }
      span:first-child{
        font-family: Montserrat-ExtraLight, Montserrat;
        font-weight: 200;

      }
      span:last-child{
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
      }
    }

    .cont {
      width: 100%;
      font-size: 7.5vw;
      line-height: 10vw;
      color: #20BFD0;
      text-align: center;
      margin: 5.461vh auto 0;
      animation-delay: .5s;
      font-weight:100;
    }

    .cli_btn{
      margin: 0 auto;
      background:rgba(6, 19, 41, 0.4);
      width: 39.7222vw;
      height: 11.111vw;
      border-radius: 5.5555vw;
      overflow: hidden;
      //margin-top:17.039vh;
      margin-top:25.9868vh;
      backdrop-filter: blur(0.8vw);
      animation-delay: .8s;
      div{
        position: relative;
        width: 39.7222vw;
        height: 11.1111vw;
        font-size:3.333vw;
        line-height: 11.111vw;
        color:#fff;
        background:rgba(32, 191, 208, 0.1);
        text-align: center;
      }
      div::after{
        content:'';
        display: inline-block;
        position: absolute;
        left:50%;
        margin-left: 6.388vw;
        top:50%;
        transform: translateY(-50%);
        width: 3.888vw;
        height: 3.888vw;
        background:url("../assets/images/ico_arrow_w.svg") center center;
        background-size:cover;
      }
    }
    .press_btn{
      border-radius: 5.5555vw;
      background: rgba(6, 19, 41, 0.4);
      div{
        border-radius: 5.5555vw;
        background: rgba(32, 191, 208, 0.5);
      }
    }

    .copyright {
      width: 100%;
      position: fixed;
      //left: 50%;
      bottom:25vw;
      //transform: translateX(-50%);
      animation-delay: 1s;
      z-index: 1;
      // font-size: 2.777vw;
      font-size: 3.333vw;
      line-height: 4.888vw;
      color: rgba(255, 255, 255, 0.4);
      text-align: center;
      a{
        color: rgba(255, 255, 255, 0.4)!important;
      }
    }

  }
</style>
